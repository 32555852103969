<template>
  <elements-button
    :color="{ name: 'success' }"
    :url="currentAuctionUrl"
    class="!md:w-full block !w-auto whitespace-nowrap rounded-full border border-white text-sm sm:!px-6 sm:text-lg"
  >
    {{ $t('cars-list.current-auction') }}
  </elements-button>
</template>

<script setup lang="ts">
import { useAuctionPagesLink } from '@autobid/ui/composables/useAuctionPagesLink'

const { APP_ID } = useRuntimeConfig().public

type Props = {
  slug: string
}
const props = defineProps<Props>()

const { getLink } = useAuctionPagesLink(APP_ID)
const currentAuctionUrl = computed(() =>
  getLink({
    page: 'current',
    slug: props.slug
  })
)
</script>
